import React from "react";
import "./Cardabout.scss";
import { Link } from "gatsby";

const Cardabout = (props) => {
  return (
    <div className="card-about">
      <img className="card-about-image" src={props.image} alt="card" />
      <h3 className="card-about-title">{props.title}</h3>
      <p className="card-about-lead">{props.lead}</p>
      <Link className="card-about-btn" to={props.btnlink}>
        {props.btntext}
      </Link>
    </div>
  );
};

export default Cardabout;
