import React from "react";
import "./Cardmember.scss";

const Cardmember = (props) => {
  return (
    <div className="card-member">
      <img className="card-member-image" src={props.image} alt="card" />
      <h3 className="card-member-title">{props.title}</h3>
      <p className="card-member-belong">{props.belong}</p>
      <p className="card-member-lead">{props.lead}</p>
    </div>
  );
};

export default Cardmember;
