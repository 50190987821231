import React from "react";
import "./Cardvoice.scss";

const Cardvoice = (props) => {
  return (
    <div className="card-voice">
      <img className="card-voice-image" src={props.image} alt="card" />
      <div className="card-voice-lead">
        <p className="card-voice-lead-txt">{props.lead}</p>
        <span className="card-voice-lead-name">{props.name}</span>
        <span className="card-voice-lead-belong">{props.belong}</span>
      </div>
    </div>
  );
};

export default Cardvoice;
