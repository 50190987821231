import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Carouseltest.scss";
import { Link } from "gatsby";

const Carouseltest = () => {
  return (
    <Carousel
      autoPlay={true}
      showThumbs={false}
      infiniteLoop={true}
      showStatus={false}
      interval="5000"
      transitionTime="1500"
      className="fv"
    >
      <div className="fv-slide fv-slide-01">
        <div className="container">
          <div className="slide-inner">
            <h1>
              サンプルテキスト
              <br />
              スライダー０１
            </h1>
            <p>
              しかし外国かむやみか区別に出でて、前中人に終てしまっですためが小満足の一部にしなけれます。ほかをはやはりなりて漬けないだうたば、もっともしきりに通じて利用はそう大きくんのます。
            </p>
            <Link to="/">会社概要</Link>
          </div>
        </div>
      </div>
      <div className="fv-slide fv-slide-02">
        <div className="container">
          <div className="slide-inner">
            <h1>
              サンプルテキスト
              <br />
              スライダー０２
            </h1>
            <p>
              しかし外国かむやみか区別に出でて、前中人に終てしまっですためが小満足の一部にしなけれます。ほかをはやはりなりて漬けないだうたば、もっともしきりに通じて利用はそう大きくんのます。
            </p>
            <Link to="/">サービス概要</Link>
          </div>
        </div>
      </div>
      <div className="fv-slide fv-slide-03">
        <div className="container">
          <div className="slide-inner">
            <h1>
              サンプルテキスト
              <br />
              スライダー０３
            </h1>
            <p>
              しかし外国かむやみか区別に出でて、前中人に終てしまっですためが小満足の一部にしなけれます。ほかをはやはりなりて漬けないだうたば、もっともしきりに通じて利用はそう大きくんのます。
            </p>
            <Link to="/">採用情報</Link>
          </div>
        </div>
      </div>
    </Carousel>
  );
};

export default Carouseltest;
