import React from "react";
import { Link } from "gatsby";
import "./Cardservice.scss";

const Cardservice = (props) => {
  return (
    <div className="card-service">
      <img className="card-service-image" src={props.image} alt="card" />
      <h3 className="card-service-title">{props.title}</h3>
      <p className="card-service-lead">{props.lead}</p>
      <Link className="card-service-btn" to={props.btnlink}>
        {props.btntext}
      </Link>
    </div>
  );
};

export default Cardservice;
