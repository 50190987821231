import React from "react";
import { Helmet } from "react-helmet";
import Layout from "./../components/Layout";
import { Link } from "gatsby";
import "./index.scss";
// import Firstview from "./../components/Firstview";
import Carouseltest from "./../components/Carouseltest";
import Cardabout from "./../components/Cardabout";
import Cardservice from "./../components/Cardservice";
import Cardmember from "./../components/Cardmember";
import Cardvoice from "./../components/Cardvoice";
import ImageSample01 from "./../images/index-sample-01.jpg";
import ImageSample02 from "./../images/index-sample-02.jpg";
import ImageSample03 from "./../images/index-sample-03.jpg";
import Icon from "./../images/favicon.ico";

const IndexPage = () => {
  const pageTitle = "ホーム";

  return (
    <Layout title="ホーム">
      {/* head */}
      <Helmet>
        <title>{pageTitle} | ORANGE</title>
        <html lang="ja" />
        <meta name="description" content="このページはモックサイトです。" />
        <link rel="icon" type="image/png" href={Icon} />
      </Helmet>

      {/* body */}
      {/* <Firstview /> */}
      <Carouseltest />
      <section className="about-section">
        <div className="container">
          <div className="section-heading">
            <h2>私たちについて</h2>
          </div>
          <div className="section-inner">
            <Cardabout
              image={ImageSample01}
              title="タイトル"
              lead="どうも極めて二二四年に得るですと、壇をはあとにはそれを人間に云わなけれて考えるないのをしないです。"
              btnlink="/"
              btntext="詳細を見る"
            />
            <Cardabout
              image={ImageSample01}
              title="タイトル"
              lead="どうも極めて二二四年に得るですと、壇をはあとにはそれを人間に云わなけれて考えるないのをしないです。"
              btnlink="/"
              btntext="詳細を見る"
            />
            <Cardabout
              image={ImageSample01}
              title="タイトル"
              lead="どうも極めて二二四年に得るですと、壇をはあとにはそれを人間に云わなけれて考えるないのをしないです。"
              btnlink="/"
              btntext="詳細を見る"
            />
          </div>
        </div>
      </section>
      <section className="service-section">
        <div className="container">
          <div className="section-heading">
            <h2>サービス概要</h2>
          </div>
          <div className="section-inner">
            <Cardservice
              image={ImageSample02}
              title="タイトル"
              lead="どうも極めて二二四年に得るですと、壇をはあとにはそれを人間に云わなけれて考えるないのをしないです。"
              btnlink="/"
              btntext="詳細を見る"
            />
            <Cardservice
              image={ImageSample02}
              title="タイトル"
              lead="どうも極めて二二四年に得るですと、壇をはあとにはそれを人間に云わなけれて考えるないのをしないです。"
              btnlink="/"
              btntext="詳細を見る"
            />
            <Cardservice
              image={ImageSample02}
              title="タイトル"
              lead="どうも極めて二二四年に得るですと、壇をはあとにはそれを人間に云わなけれて考えるないのをしないです。"
              btnlink="/"
              btntext="詳細を見る"
            />
            <Cardservice
              image={ImageSample02}
              title="タイトル"
              lead="どうも極めて二二四年に得るですと、壇をはあとにはそれを人間に云わなけれて考えるないのをしないです。"
              btnlink="/"
              btntext="詳細を見る"
            />
            <Cardservice
              image={ImageSample02}
              title="タイトル"
              lead="どうも極めて二二四年に得るですと、壇をはあとにはそれを人間に云わなけれて考えるないのをしないです。"
              btnlink="/"
              btntext="詳細を見る"
            />
          </div>
        </div>
      </section>
      <section className="recruit-section">
        <div className="container">
          <h2>新卒、第二新卒募集中</h2>
          <p>
            何は十月とうていこういう［＃「ごとというののために起るましなら。何だか今に注文学はもしその建設ありませくらいをなっがいるないをも教育しないないが、いっそにも突っついないででだろ。時勢にあるたものはけっして生涯を要するにたでで。
          </p>
          <Link to="/">採用情報</Link>
        </div>
      </section>
      <section className="member-section">
        <div className="container">
          <div className="section-heading">
            <h2>社員紹介</h2>
          </div>
          <div className="section-inner">
            <Cardmember
              image={ImageSample03}
              title="山田 太郎"
              belong="XXX課"
              lead="どうも極めて二二四年に得るですと、壇をはあとにはそれを人間に云わなけれて考えるないのをしないです。"
            />
            <Cardmember
              image={ImageSample03}
              title="山田 太郎"
              belong="XXX課"
              lead="どうも極めて二二四年に得るですと、壇をはあとにはそれを人間に云わなけれて考えるないのをしないです。"
            />
            <Cardmember
              image={ImageSample03}
              title="山田 太郎"
              belong="XXX課"
              lead="どうも極めて二二四年に得るですと、壇をはあとにはそれを人間に云わなけれて考えるないのをしないです。"
            />
            <Cardmember
              image={ImageSample03}
              title="山田 太郎"
              belong="XXX課"
              lead="どうも極めて二二四年に得るですと、壇をはあとにはそれを人間に云わなけれて考えるないのをしないです。"
            />
          </div>
        </div>
      </section>
      <section className="voice-section">
        <div className="container">
          <div className="section-heading">
            <h2>お客様の声</h2>
          </div>
          <div className="section-inner">
            <Cardvoice
              image={ImageSample03}
              lead="十月がも極めて込み入っば立っないでうずて、現にもうおくから妨害は突然ないますものない。しかし実病気を送らてしかいな気ませで、ずるずるべったりへも、勢いそれかかかるてさせるうべき云っれたないと落ちて、符はいうばいありた。"
              name="XXX株式会社"
              belong="XXX課"
            />
            <Cardvoice
              image={ImageSample03}
              lead="十月がも極めて込み入っば立っないでうずて、現にもうおくから妨害は突然ないますものない。しかし実病気を送らてしかいな気ませで、ずるずるべったりへも、勢いそれかかかるてさせるうべき云っれたないと落ちて、符はいうばいありた。"
              name="XXX株式会社"
              belong="XXX課"
            />
            <Cardvoice
              image={ImageSample03}
              lead="十月がも極めて込み入っば立っないでうずて、現にもうおくから妨害は突然ないますものない。しかし実病気を送らてしかいな気ませで、ずるずるべったりへも、勢いそれかかかるてさせるうべき云っれたないと落ちて、符はいうばいありた。"
              name="XXX株式会社"
              belong="XXX課"
            />
            <Cardvoice
              image={ImageSample03}
              lead="十月がも極めて込み入っば立っないでうずて、現にもうおくから妨害は突然ないますものない。しかし実病気を送らてしかいな気ませで、ずるずるべったりへも、勢いそれかかかるてさせるうべき云っれたないと落ちて、符はいうばいありた。"
              name="XXX株式会社"
              belong="XXX課"
            />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default IndexPage;
